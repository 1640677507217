.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  text-align: center;
  img {
    max-width: 500px;
  }
  h1 {
    margin: 1.5rem;
  }
}
