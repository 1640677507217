@import "../var";

.header {
  // position: sticky;
  // top: 0;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: rgba(#000, 0.46);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem 2rem;
  min-height: 67px;
  .logo {
    cursor: pointer;
    img {
      max-height: 68px;
      @include max(767) {
        max-height: 58px;
      }
    }
  }
  .toggleBtn {
    display: none;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 22px;
  }
  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(black, 0.85);
    display: none;
    z-index: 9;
    &.show {
      display: block;
    }
  }
  @include max(991) {
    height: 55px;
    padding: 1rem 1.5rem;
    .toggleBtn {
      display: block;
    }
  }
}
.navbar_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5rem;
  .navbar {
    display: flex;
    align-items: center;
    color: #fff;
    span {
      padding: 2px 2.5rem;
      border-right: 1px solid #fff;
      cursor: pointer;
      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }
  .navbar_btn {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
  @include max(991) {
    display: block;
    position: fixed;
    z-index: 19;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 2rem;
    background-color: #000;
    width: 240px;
    transition: 0.14s linear;
    transform: translate(-100%);
    .navbar {
      display: block;
      margin-bottom: 1.5rem;
      span {
        border: none;
        display: block;
        font-size: 16px;
        padding: 2px 0;
        margin-bottom: 5px;
      }
    }
    .navbar_btn {
      display: block;
      button {
        display: block;
        margin-bottom: 10px;
        width: 100%;
      }
    }
    &.open {
      transform: translate(0);
    }
  }
}
