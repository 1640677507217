@import "../var";

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  padding-top: 67px;
  display: flex;
  align-items: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    min-height: auto;
    padding: 78px 0px;
  }

  .banner_content {
    color: #fff;
    //  &::after {
    //    content: "";
    //    background-color: #fff;
    //    background-image: url("../../assets/images/lines.svg");
    //    background-size: 155px 45px;
    //  }
    .banner_left_content {
      font-weight: 700;
      font-size: 4rem;
      letter-spacing: 0.02em;
      color: #ffffff;
      line-height: 4.5rem;
      @media screen and (max-width: 768px) {
        font-size: 3rem;
        text-align: center;
        display: none;
      }

      br {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      img {
        width: 29.5rem;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    p {
      width: 400px;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding: 15px 15px;
      }
    }
  }
}
.textLarge {
  font-weight: 700;
  font-size: 34px;
  line-height: 47px;
  margin-bottom: 20px;
  margin-top: 3px;
}

// About us scss
.aboutUs {
  padding: 50px 0px;
  font-family: "Roboto", sans-serif;
  @media screen and (max-width: 768px) {
    padding: 10px 0px;
  }
  .leftSide {
    .customCard {
      border: 0px;
      display: flex;
      box-shadow: none;
      background: transparent;
      .customCardbody {
        .customCardtitle {
          font-style: italic;
          font-weight: 500;
          font-size: 35px;
          line-height: 41px;
          letter-spacing: 0.03em;
          @media screen and (max-width: 768px) {
            font-size: 26px;
          }
          br {
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }
        .customCardtext {
          font-style: normal;
          font-size: 15px;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: #5c5c5c;
          margin: 15px 0px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
          br {
            @media screen and (max-width: 768px) {
              display: none;
            }
          }
        }

        span {
          font-weight: 300;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.02em;
          margin-left: 10px;
        }
        .customCardbtn {
          position: relative;
          position: relative;
          padding: 10px;
          width: 171px;
          border: 2px solid #fbaf24;
          height: 50px;
          margin-left: 15px;
          margin: 30px 11px;
          button {
            background: #fbaf24;
            border: 0px;
            padding: 0px 30px;
            height: 47.61px;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: #ffffff;
            position: absolute;
            top: -10px;
            left: -14px;
            width: 171px;
          }
        }
      }
    }
  }
}
.aboutbg {
  // background-image: url(../../assets/images/aboutbg.png);
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // height: 300px;
  padding: 50px 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 50px 0px;
  }
  .aboutApp {
    .customCardbody {
      @media screen and (max-width: 768px) {
        padding: 30px 0px;
      }
      h6 {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.03em;
        background-color: #fbaf24;
        color: #ffffff;
        padding: 3px 10px;
        width: fit-content;
        margin-top: 15px;
      }
      .customCardtitle {
        font-style: italic;
        font-weight: 500;
        font-size: 35px;
        line-height: 41px;
        letter-spacing: 0.03em;
        @media screen and (max-width: 768px) {
          font-size: 26px;
        }
        br {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      .customCardtext {
        font-style: normal;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: #5c5c5c;
        margin: 20px 0px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
        br {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}
.featureSection {
  
  .featurebg {
    background-image: linear-gradient(
        rgba(149, 149, 149, 0.056),
        rgba(0, 0, 0, 0.5)
      ),
      url(../../assets/images/backgroundImg.png);
    height: 700px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    // align-items: center;

    // justify-content: center;
    // margin: 20px 0px;
    @media screen and (max-width: 768px) {
      height: auto;
       padding: 50px 0px;
    }

    .feature {
      text-align: center;
      width: 100%;
      max-height: 400px;
      .slider {
        background: transparent !important;
        color: "#fff";
        
      }
      h4 {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 10px;
        // margin: 15px 0px;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }
      }
      p {
        color: #ffffff;
        font-size: 18px;
       margin-top: 10px;
       letter-spacing: 0.5px;
        font-weight: 500;
        @media screen and (max-width: 768px) {
          font-size: 13px;
        }
        br {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      nav {
        @media screen and (max-width: 768px) {
          bottom: -40px;
        }
        bottom: 40px;
      }
      img {
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        width: 70%;
        margin: auto;
      }
    }
  }
  .featureCards {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 1px 13px 35px 3px rgba(0, 0, 0, 0.05);
    h4 {
      font-size: 18px;
      line-height: 23px;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #2e2e2e;
    }
    img {
      padding: 20px;
      background-color: #fbaf24;
    }
  }
}

// getInTouch  start scss

.getInTouch {
  background-image: url(../../assets/images/getintouchbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: "500px";
  padding: 0px 0px;
  @media screen and (max-width: 768px) {
    padding: 0px 0px;
  }
  @media only screen and (max-width: 1400px) {
    height: "300px";
  }
  .flex {
    width: 60%;
    background: #fbaf24;
    padding: 130px 10px;
    display: flex;
    color: #fff;

    flex-direction: column;
    align-items: center;
    p {
      font-size: 15px;
      margin-top: 50px;
    }
    .icons {
      display: flex;
      margin-top: 20px;
      width: 50%;
      justify-content: space-evenly;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .contactForm {
    margin-top: 50px;
    padding-left: 20px;
    :global(.form-floating .is-invalid) {
      border: 1px solid #f00;
    }
  }
  .customForm {
    background-color: #fff;
    padding: 0px 30px 0px 0px;
    display: flex;
    margin: 30px 0px;
    @media screen and (max-width: 768px) {
      padding: 30px 30px;
    }
  p {
      font-size: 16px;
      font-weight: 500;
      color:#F8AF24;
      line-height: 19px;
      margin-bottom: 10px;
      letter-spacing: 0.02em;
    }
    span {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
    }
    h5 {
      font-weight: 700;
      font-size: 34px;
      line-height: 47px;
      margin-bottom: 20px;
      margin-top: 3px;
    }
    input {
      background: #ffffff;
      box-shadow: 1px 13px 35px 3px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      border: 0px;
      height: 50px;
    }
    label {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 300;
      padding: 10px 0.75rem;
    }
    textarea {
      background: #ffffff;
      box-shadow: 1px 13px 35px 3px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      border: 0px;
      height: 150px;
    }
    button {
      background-color: #fbaf24;
      width: 199px;
      height: 47.61px;
      border: 0px;
      color: #fff;
      margin: 40px 0px;
      font-size: 16px;
    }
  }
}

// footer start scss
.footer {
  background-color: #2e2e2e;
  color: #fff;
  padding: 80px 0px;
  @media screen and (max-width: 768px) {
    padding: 20px 0px;
  }
  h5 {
    font-weight: 400;
    font-size: 22px;
    line-height: 19px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #ffffff9e;
    margin: 15px 0px;
  }
  label {
    color: rgba(0, 0, 0, 0.4);
  }
  input {
    @media screen and (max-width: 768px) {
      padding: 0px 30px !important;
      width: 70%;
    }
  }
  button {
    background-color: #fbaf24;
    width: 199px;
    height: 47.61px;
    border: 0px;
    color: #fff;
    margin: 20px 0px;
    font-size: 16px;
  }
  .socialIconFooter {
    display: flex;
    width: 80px;
    // background: #fbaf24;
    height: 35px;
    align-items: center;
    justify-content: space-around;
    margin: 15px auto;
  }
  .social_icon {
    display: flex;
    width: 130px;
    // background:  #FBAF24;
    height: 35px;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0px;
    img {
      width: 25px;
    }
  }
}
.slideItem {
  // background-color: rgba(0, 0, 0, 0.46);
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: center;
  // img{
  //   mix-blend-mode: luminosity;
  //   -webkit-filter: contrast(1.1);
  //   filter: contrast(1.1);

  // }
  .slideItemContent {
    padding: 10px;
    width: 70%;
    @media screen and (min-width: 1024px ) and (max-width: 1440px ) {
      width: 100%;
    }
  }
}

.sliderComponet{
  .awssld__bullets{
    display: none !important;
  
  }
}
