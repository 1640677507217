@import "~bootstrap/scss/bootstrap";
@import "./var";
@import "./common";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {  
  font-family: 'Roboto', sans-serif;
}
// ========= theme Table ============
.theme_table {
  font-size: 14px;
  .table {
    border-color: $primaryLight;
    // border-collapse: separate;
    // border-spacing: 0 10px;
    & > :not(caption) > * > * {
      border-bottom-width: 0;
      padding: 0.7rem 0.7rem;
    }
    tr {
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px #e0e0e0;
    }
    th {
      font-weight: 500;
      background-color: $primaryLight;
      color: $titleDark;
    }
    td {
      color: $titleColor;
      vertical-align: middle;
    }
  }
}
// ========= theme Table end ============

// ======== theme dropdown menu =========
.themeDropdown_menu {
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(107, 108, 126, 0.25);
  border: none;
  .themeDropdown_menuIcon {
    margin-right: 12px;
    font-size: 1.1em;
  }
  li,
  a {
    padding: 6px 14px;
    white-space: nowrap;
    transition: 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    color: $titleColor;
    &:hover {
      background-color: #f6f6f6;
    }
  }
}
// ======== theme dropdown menu end=========
// ======== theme Drawer ===================
.theme_drawer {
  &.offcanvas {
    max-width: 360px;
  }
}
// ======== theme Drawer end================

.loadingLines {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ======= tabs ==========
.theme_tabs {
  .nav-tabs {
    font-size: 14px;
    border-color: #f2f2f2;
    .nav-link {
      font-weight: 500;
      border: none;
      border-bottom: 1px solid transparent;
      &.active {
        color: $secondary;
        border-color: currentColor;
        background-color: transparent;
      }
    }
  }
}
.theme_box {
  @include box;
  height: 100%;
}
  


