$primary: #385a64;
$secondary: #ff725c;
$primaryLight: #385a6438;
$titleDark: #323232;
$titleColor: #525252;
$borderColor: #aaa;
$textColor: #828282;

@mixin min($value) {
  @media screen and (min-width: ($value + "px")) {
    @content;
  }
}
@mixin max($value) {
  @media screen and (max-width: ($value + "px")) {
    @content;
  }
}
@mixin minMax($min, $max) {
  @media (min-width: ($min + "px")) and (max-width: ($max + "px")) {
    @content;
  }
}
@mixin box {
  background-color: #fff;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: $titleColor;
  box-shadow: 0 0 18px -6px rgba($secondary, 0.25);
  transition: 0.15s linear;
  &:hover {
    box-shadow: 0px 0px 26px -6px rgba($secondary, 0.27);
  }
}
