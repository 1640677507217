.awssld__controls__arrow-left, .awssld__controls__arrow-right{
    height:17px !important;
    color:#fff !important;
  }
  
  .awssld__bullets .awssld__bullets--active{
    background:#fff !important
  }
  .awssld__bullets button {
width:10px;
height:10px;
background:#fff !important
  }
  .awssld__controls button .awssld__controls__arrow-left, .awssld__controls button .awssld__controls__arrow-right{
    opacity:unset !important;
  }

.awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after{
    background:#fff !important
}