// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
html {
  font-size: 16px;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
}
body {
  // font-family: "Noto Sans", sans-serif;
  // font-family: "Roboto", sans-serif;
}
img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}
ul {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: clamp(30px, calc(1.05rem + 3vw), 48px);
}
h2 {
  font-size: clamp(20px, 3vw, 36px);
}
h3 {
  font-size: clamp(24px, 2.7vw, 28px);
}
h4 {
  font-size: clamp(20px, 2.2vw, 24px);
}
h5 {
  font-size: clamp(16px, 1.9vw, 18px);
}
h6 {
  font-size: 14px;
  font-weight: 400;
}
p {
  font-weight: 300;
}
// $spacings: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
// $sides: (top, bottom, left, right);

// @each $space in $spacings {
//   @each $side in $sides {
//     .m#{str-slice($side, 0, 1)}_#{$space} {
//       margin-#{$side}: #{$space}px !important;
//     }

//     .p#{str-slice($side, 0, 1)}_#{$space} {
//       padding-#{$side}: #{$space}px !important;
//     }
//   }
// }
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1480px;
  }
}
.form-label {
  color: $textColor;
  margin-bottom: 0.2rem;
}
.form-check-label {
  color: $textColor;
}
.form-check-input {
  &:checked {
    background-color: $secondary;
    border-color: $secondary;
  }
}
.form-control {
  border-color: #ded2d9;
  min-height: 40px;
  &::placeholder {
    color: #bbb;
    font-weight: 300;
  }
  &:focus {
    box-shadow: none;
  }
}
button {
  &.btn {
    padding: 0.4rem 1.2rem;
    &:focus {
      box-shadow: none;
    }
    &.btn-secondary {
      color: #fff;
    }
  }
}
// ======== common classes============
ul.list {
  padding-left: 1.3rem;
}
.text_small {
  font-size: 14px;
}
.theme_txtLink {
  font-size: 14px;
  padding: 3px;
  color: $primary;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
