@import "../var";

.dashLayout {
  background-color: #f7f6f9;
  --sidebarWidth: 230px;
  @include max(767) {
    --sidebarWidth: 200px;
  }
}
.dashPage {
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  background-color: #f7f7f7;
  transition: 0.15s linear;
}
.dashSidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: 4px 0px 19px -3px rgb(0 0 0 / 50%);
  background-color: $primary;
  max-width: var(--sidebarWidth);
  width: var(--sidebarWidth);
  z-index: 999;
  transition: 0.15s linear;
}
.dashBody {
  height: 100vh;
  overflow-y: auto;
}
.dashBody_content {
  padding: 1rem 1rem 0;
}
